import Vue from 'vue'

Vue.filter('truncate', function (value, length, suffix = '...') {
  length = length || 15

  if (!value || typeof value !== 'string') {
    return ''
  }

  if (value.length <= length) {
    return value
  }

  return value.substring(0, length) + suffix
})

Vue.filter('price', function (value, options = { style: 'currency', currency: 'RON', minimumFractionDigits: 2}) {
  return new Intl.NumberFormat('ro-RO', options).format(Number(value));
})

Vue.filter('number', function (value, options = { style: 'decimal', minimumFractionDigits: 2}) {
  return new Intl.NumberFormat('ro-RO', options).format(Number(value));
})

Vue.filter('date', function (value, options = { format: 'ro-RO' }) {
  try {
    return new Intl.DateTimeFormat(options.format).format(value)
  }catch (e) {
    return '-'
  }
})
