import { get } from 'lodash-es'

let loggingOut = false

export default function ({ app }) {
  app.$axios.isAuth = false

  app.$axios.enableAuth = () => {
    app.$axios.isAuth = true
  }

  app.$axios.disableAuth = () => {
    app.$axios.isAuth = false
  }

  app.$axios.onRequest(config => {
    if (!config) {
      config = {}
    }
    //config.mode = 'no-cors'
    //console.log('onRequest', config)
  })

  app.$axios.onResponse(response => {
    //console.log('onResponse', response)
  })

  app.$axios.onRequestError(() => {
    //console.log('onRequestError', error)
  })

  app.$axios.onResponseError(error => {
    if (!error.response) {
      app.$toast.error(app.i18n.t('error.offline'))
    }

    const reject = Promise.reject(error)
    let status

    if (error.response) {
      status = get(error.response, 'status')
    }

    if (!app.$axios.isAuth && status === 401 && !loggingOut) {
      loggingOut = true

      app.$auth.strategy._logoutLocally().finally(() => {
        loggingOut = false
        app.router.push({ name: 'login' }, () => {
          app.$toast.info(app.i18n.t('general.messages.force_logout'))
        })
        return reject
      })
    }

    if (!app.$axios.isAuth) {
      if (status === 403) {
        // app.$toast.error(app.i18n.t('error.403'))
      } else {
        // app.$toast.error(app.i18n.t('error.5xx'))
      }
    }

    return reject
  })
}
