export default {
  methods: {
    assemblyWorkhoursList (restaurantSettings) {
      var workhoursArray = []
      var dayName
      var localeDayName

      let localeData = this.$moment.localeData();
      var a = this.$moment();
      var b = this.$moment().add(7, 'days');

      for (var m = this.$moment(a); m.isBefore(b); m.add(1, 'days')) {
        dayName = m.locale('en').format('dddd').toLowerCase()
        localeDayName = m.locale(localeData._abbr).format('dddd')
        workhoursArray.push({
          key: m.locale('en').format('dddd'),
          label: localeDayName.charAt(0).toUpperCase() + localeDayName.slice(1),
          date: m,
          start: this._.get(restaurantSettings, `workhour_${dayName}_a`, undefined),
          end: this._.get(restaurantSettings, `workhour_${dayName}_a`, undefined)
        })
      }
      workhoursArray = workhoursArray.filter((period) => period.start !== undefined)
      if(!workhoursArray.length) {
        return {}
      }
      return {
        today: workhoursArray.splice(0, 1)[0],
        week: workhoursArray
      }
    }
  }
}
