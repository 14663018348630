import {parseQuery} from "../../.nuxt/auth/utilities";
import Cookie from 'js-cookie'

export default {
  async mounted() {
    await this.checkOauthRedirectLogin()
    await this.checkLoginPersistence()
  },
  methods: {
    async checkOauthRedirectLogin () {
      const params = parseQuery(this.$route.hash.substr(1))
      if (params.access_token) {
        return this.doLogin('password_grant_custom', `${params.token_type} ${params.access_token}`, () => {
          if(this.$store.getters['embed/redirectAfterSocialLogin'] === true) {
            this.$store.dispatch('embed/setRedirectAfterSocialLogin', {status: false})
            this.$router.replace({name: 'embed-cart'})
          } else {
            this.$router.replace({name: 'root'})
          }
        })
      }
    },
    async checkLoginPersistence () {
      let token = Cookie.get('auth._token.password_grant_custom')
      if(token){
        return this.doLogin('password_grant_custom', token)
      }
    },
    async doLogin(strategy = 'password_grant_custom', token, callback) {
      await this.$auth.setStrategy(strategy)
      await this.$auth.setToken(
        this.$auth.strategy.name,
        token
      )
      await this.$auth.init()
      return callback ? callback() : true
    }
  }
}
