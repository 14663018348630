import Vue from 'vue'

import VueSlick from "vue-slick";
Vue.component('VueSlick', VueSlick);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

Vue.component('cookie-law', CookieLaw);
import CookieLaw from 'vue-cookie-law'
Vue.component('CookieConsent',() => import( '~/components/CookieConsent'));
