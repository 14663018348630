import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { get } from 'lodash-es'

Vue.use(VueI18n);

export default async ({ app, store }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  var appMessages = {}
  if (process.env.NODE_ENV === 'development') {
    appMessages.ro = require('~/i18n/ro.json')
  }
  app.i18n = new VueI18n({
    locale: store.getters['lang/locale'],
    fallbackLocale: 'ro',
    messages: appMessages
  })

  if (process.client) {
    let locale = store.getters['lang/locale']
    let localesTranslation = store.getters['lang/localesTranslation']
    await loadMessages(app, locale, localesTranslation[locale] ? localesTranslation[locale] : locale, app.$axios)
    await loadPages(app, locale, app.$axios)
  }
}

/**
 * @param app
 * @param {String} locale
 * @param http
 */
export async function loadMessages (app, locale, localeTranslations, http) {
  http.defaults.headers.common['Accept-Language'] = locale;
  app.$moment.locale(locale)
  app.store.dispatch('lang/setLocale', { locale: locale })
  app.head.htmlAttrs.lang = locale

  if (Object.keys(app.i18n.getLocaleMessage(locale)).length === 0) {
    const messages = await http.get(`api/webclient/translations?lang=${locale}`)
      .then((r) => {
        return get(r, 'data')
      })
      .catch((err) => {
        console.log('Failed to fetch translations')
      })

    app.i18n.setLocaleMessage(locale, messages)
  }

  if (app.i18n.locale !== locale) {
    app.i18n.locale = locale
  }
}
/**
 * @param app
 * @param {String} locale
 * @param http
 */
export async function loadPages (app, locale, http) {
  const messages = await http.get(`api/webclient/pages?lang=${locale}`)
    .then((r) => {
      app.store.dispatch('lang/setPages', { pages: get(r, 'data') })
    })
    .catch((err) => {
      console.log('Failed to fetch pages')
    })
}
/**
 * @param app
 * @param {String} locale
 * @param http
 */
export async function changeLanguage (app, locale, http) {
  http.defaults.headers.common['Accept-Language'] = locale;
  app.$moment.locale(locale);
  app.$store.dispatch('lang/setLocale', { locale: locale });

  if (Object.keys(app.$i18n.getLocaleMessage(locale)).length === 0) {
    const messages = await http.get(`api/webclient/translations?lang=${locale}`)
      .then((r) => {
        return get(r, 'data')
      })
      .catch((err) => {
        console.log('Failed to fetch translations')
      })

    app.$i18n.setLocaleMessage(locale, messages)
  }

  if (app.$i18n.locale !== locale) {
    app.$i18n.locale = locale
  }
}
