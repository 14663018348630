// state
export const state = () => ({
  coords: {},
  code: undefined,
  message: undefined,
  cityId: '0',
  countyId: '0',
})

// getters
export const getters = {
  coords: state => state.coords,
  locationCode: state => state.code,
  locationMessage: state => state.message,
  cityId: state => state.cityId,
  countyId: state => state.countyId
}

// mutations
export const mutations = {
  SET_GEO_LOCATION (state, { coords, code, message }) {
    state.coords = coords
    state.code = code
    state.message = message
  },
  SET_LOCATION (state, { cityId, countyId }) {
    state.cityId = cityId
    state.countyId = countyId
  }
}

// actions
export const actions = {
  setGeoLocation ({ commit }, { coords, code, message }) {
    commit('SET_GEO_LOCATION', { coords, code, message })
  },
  setLocation ({ commit }, { cityId, countyId }) {
    commit('SET_LOCATION', { cityId, countyId })
  }
}
