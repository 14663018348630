import Vue from 'vue'
import {get} from 'lodash-es';

class Api {
  constructor(app) {
    this.app = app
  }

  /**
   * Login
   *
   * @returns Promise
   */
  login(username, password, code = undefined) {
    this.app.$axios.enableAuth()

    return this.app.$auth
      .loginWith('password_grant_custom', {
        data: {
          grant_type: 'password',
          client_id: process.env.CLIENT_ID,
          client_secret: process.env.CLIENT_SECRET,
          username,
          password,
          scope: '',
          code
        }
      })
      .finally((r) => {
        this.app.$axios.disableAuth()
      })
  }

  /**
   * @param data
   * @returns {Promise<any>}
   */
  updateUser(data) {
    if (get(data, 'password', '').length < 1) {
      delete data.password
    }
    return this.app.$axios
      .put('/api/webclient/user', data)
  }

  /**
   * @param data
   * @returns {Promise<any>}
   */
  getUser(data) {
    return this.genericRequest(
      `/api/webclient/user`,
      [
        'id',
        'name',
        'email',
        'client_name',
        'birthday'
      ]
    )
  }

  /**
   * Register
   *
   * @returns Promise
   */
  register(phone, email, password, confirm_password) {
    this.app.$axios.enableAuth()

    let {resolved} = this.app.router.resolve({
      name: 'login',
      params: {
        token: '--TOKEN--',
        email: email
      }
    })

    return this.app.$axios
      .post('/api/webclient/register', {
        phone,
        email,
        password,
        confirm_password,
        url: `${window.location.protocol}//${window.location.host}${(this.app.router.mode.toLowerCase() === 'hash' ? '/#' : '')}${resolved.path.replace(/--TOKEN--/, '{token}')}`
      })
      .finally((r) => {
        this.app.$axios.disableAuth()
      })
  }

  /**
   * Password reset
   *
   * @returns {Promise<any>}
   */
  activateAccount(token, email) {
    return this.app.$axios
      .post('/api/webclient/activate', {
        token,
        email
      })
  }

  /**
   * Logout
   */
  logout() {
    return this.app.$auth
      .logout()
  }

  /**
   * Password forgot
   *
   * @returns {Promise<any>}
   */
  passwordForgot(email) {
    let {resolved} = this.app.router.resolve({
      name: 'password-reset'
    })

    return this.app.$axios
      .post('/api/webclient/forgot', {
        email,
        url: `${window.location.protocol}//${window.location.host}${(this.app.router.mode.toLowerCase() === 'hash' ? '/#' : '')}${resolved.path}#token={token}`
      })
  }

  /**
   * Password reset
   *
   * @returns {Promise<any>}
   */
  passwordReset(token, email, password, passwordConfirmation) {
    return this.app.$axios
      .post('/api/webclient/reset', {
        token,
        email,
        password,
        password_confirmation: passwordConfirmation
      })
  }

  /**
   * Send contact data
   *
   * @returns {Promise<any>}
   */
  sendContact(name, email, message) {
    return this.app.$axios
      .post('contact', {
        name,
        email,
        message
      })
  }

  /**
   * Get all Contents
   *
   * @returns {Promise<any>}
   */
  getContents(fields) {
    if (fields === undefined) {
      fields = [
        'id',
        'title',
        'slug',
        'content',
        'meta_title',
        'meta_description',
        'tags',
        'attributes'
      ];
    }
    return this.genericRequest(
      `/api/webclient/content`,
      fields
    )
  }

  /**
   * Get a Content item by id
   *
   * @param slug
   * @returns {Promise<any>}
   */
  getContent(slug) {
    return this.genericRequest(
      `/api/webclient/content/${slug}`,
      [
        'id',
        'title',
        'content',
        'meta_title',
        'meta_description',
        'tags',
        'attributes'
      ]
    )
  }

  /**
   * @returns {Promise<any>}
   */
  getAddresses() {
    return this.genericRequest(
      `/api/webclient/address`,
      [
        'id',
        'name',
        'phone',
        'street_no',
        'street_ap',
        'county_id',
        'city_id',
        'street_id',
        'created_at',
        'street.name',
        'county.name',
        'city.name'
      ],
      [
        'county',
        'city',
        'street'
      ],
      [
        'county_name',
        'city_name',
        'street_name'
      ]
    )
  }

  /**
   * @param address_id
   * @returns {Promise<AxiosResponse<T>>}
   */
  deleteAddress(address_id) {
    return this.app.$axios
      .delete(`/api/webclient/address/${address_id}`)
  }

  /**
   * @param address_id
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  updateAddress(address_id, data) {
    return this.app.$axios
      .put(`/api/webclient/address/${address_id}`, data)
  }

  /**
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  createAddress(data) {
    return this.app.$axios
      .post(`/api/webclient/address`, data)
  }

  /**
   * @returns {Promise<any>}
   */
  getCounties(fields = [], withs = [], appends = [], conditions = {}, hasRestaurants = false) {
    var extraParams = {}
    if(hasRestaurants) extraParams.hasRestaurants = true
    if(!fields.length) fields = [
      'id',
      'name',
      'shortcode',
    ]
    return this.genericRequest(
      `/api/webclient/localization`,
      fields,
      withs,
      appends,
      conditions,
      [],
      extraParams
    )
  }

  /**
   * @returns {Promise<any>}
   */
  getCitiesByCountyId(countyId, hasRestaurants = false) {
    var extraParams = {}
    if(hasRestaurants) extraParams.hasRestaurants = true
    return this.genericRequest(
      `/api/webclient/localization`,
      [
        'id',
        'name',
        'shortcode',
        'city.id',
        'city.county_id',
        'city.name',
      ],
      [
        'cities'
      ],
      [],
      {
        id: countyId
      },
      [],
      extraParams
    ).then((response) => {
      return get(response, 'data.data.0.cities')
    })
  }

  /**
   * @param cityId
   * @returns {Promise<any>}
   */
  getStreetsByCityId(cityId) {
    return this.app.$axios.get(`/api/webclient/localization/${cityId}`)
  }

  /**
   * @returns {Promise<any>}
   */
  getOrders(fields = [], withs = [], appends = [], conditions = {}, sortBy = []) {
    if(!fields.length) {
      fields = [
        'id',
        'phone',
        'address_name',
        'county_id',
        'city_id',
        'street_id',
        'street_no',
        'street_ap',
        'remarks',
        'order_reception',
        'order_sent',
        'order_canceled',
        'order_delivered',
        'delivery_time',
        'status',
        'created_at',
        'updated_at',
        'payments.id',
        'payments.payment_type_id',
        'payments.amount',
        'payments.created_at',
        'payments.updated_at'
      ]
    }
    if(!withs.length) {
      withs = [
        'payments',
        'products'
      ]
    }
    if(!appends.length) {
      appends = [
        'county_name',
        'city_name',
        'street_name',
        'amount',
        'client_display_name'
      ]
    }
    return this.genericRequest(
      `/api/webclient/order`,
      fields,
      withs,
      appends,
      conditions,
      sortBy
    )
  }

  /**
   * @returns {Promise<any>}
   */
  getVouchers(where = {}) {
    return this.genericRequest(
      `/api/webclient/voucher`,
      [
        'id',
        'code',
        'value',
        'completed_at',
        'used_at',
        'created_at',
        'updated_at'
      ],
      [],
      [
        'percent',
        'used_at_restaurant'
      ],
      where
    )
  }

  /**
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  validateVoucher(data) {
    return this.app.$axios
      .post(`/api/webclient/cart/validate-voucher`, data)
  }

  /**
   * @param restaurantId
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  addReview(restaurantId, data) {
    return this.app.$axios
      .post(`/api/webclient/restaurant/${restaurantId}/review`, data)
  }

  /**
   * @param restaurantId
   * @param page
   * @param perPage
   * @param sortBy Array
   * @returns {Promise<any>}
   */
  getReviews(restaurantId, page = 1, perPage = 15, sortBy = []) {
    return this.genericRequest(
      `/api/webclient/restaurant/${restaurantId}/review`,
      [
        'id',
        'user_id',
        'anonym',
        'rating',
        'review',
        'created_at',
        'updated_at',
        'reply.id',
        'reply.user_id',
        'reply.anonym',
        'reply.rating',
        'reply.review',
        'reply.created_at',
        'reply.updated_at'
      ],
      [
        'replies'
      ],
      [
        'reviewer',
        'acquisition_verified'
      ],
      {},
      sortBy,
      {
        page,
        perPage
      }
    )
  }

  /**
   * @returns {Promise<any>}
   */
  getNotifications() {
    return this.genericRequest(
      `/api/webclient/notification`,
      [
        'id',
        'image',
        'title',
        'message',
        'archived_at',
        'read_at',
        'created_at',
        'updated_at'
      ]
    )
  }

  /**
   * @param notificationId
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  updateNotification(notificationId, data) {
    let params = Object.keys(data).map(function (prop) {
        return [prop, data[prop]].map(encodeURIComponent).join('=');
      }).join('&')
    return this.app.$axios
      .put(`/api/webclient/notification/${notificationId}?${params}`)
  }

  /**
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  updateMultipleNotifications(data) {
    let params = Object.keys(data).map(function (prop) {
        return [prop, data[prop]].map(encodeURIComponent).join('=');
      }).join('&')
    return this.app.$axios
      .put(`/api/webclient/notification/update-multiple?${params}`)
  }

  /**
   * @param notificationId
   * @returns {Promise<AxiosResponse<T>>}
   */
  deleteNotification(notificationId) {
    return this.app.$axios
      .delete(`/api/webclient/notification/${notificationId}`)
  }

  /**
   * @param data
   * @returns {Promise<AxiosResponse<T>>}
   */
  deleteMultipleNotifications(data) {
    let params = Object.keys(data).map(function (prop) {
      return [prop, data[prop]].map(encodeURIComponent).join('=');
    }).join('&')
    return this.app.$axios
      .delete(`/api/webclient/notification/delete-multiple?${params}`)
  }

  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  getRestaurant(slug, fields, withs, appends) {
    if (fields === undefined) {
      fields = [
        'id',
        'display_name',
        'county',
        'city',
        'address',
        'address_no',
        'slug',
        'settings',
        'phone_numbers',
        'email_addresses',
        'offers_delivery',
        'offers_pickup',
        'enable_order',
        'delivery_time',
        'payment_type.label',
        'category.title',
        'category.id',
        'category.order',

        'product.id',
        'product.product_name',
        'product.product_image',
        'product.measurement',
        'product.public_info',
        'product.meta_title',
        'product.meta_description',
        'product.product_tags',
        'product.disable_order',
        'product.slug',
        'product.order',
        'product.created_at',
        'product.category.id',

        'payment_type.id',
        'payment_type.label',

        'image.id',
        'image.image',
        'image.created_at',
      ]
    }
    if (withs === undefined) {
      withs = [
        'categories',
        'products',
        'paymentTypes',
        'images'
      ]
    }
    if (appends === undefined) {
      appends = [
        'ratingsummary',
        'logo_filename',
        'cover_filename'
      ]
    }
    return this.genericRequest(
      `/api/webclient/restaurant/${slug}`,
      fields,
      withs,
      appends
    )
  }

  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  getRestaurantProducts(slug, fields, withs, appends) {
    if (fields === undefined) {
      fields = [
        'id',
        'product_name',
        'display_name',
        'product_image',
        'measurement',
        'public_info',
        'meta_title',
        'meta_description',
        'product_tags',
        'disable_order',
        'slug',
        'order',
        'created_at',
        'category.id',
        'associate_product.id',
        'associate_product.product_name',
        'associate_product.display_name',
        'associate_product.public_info',
        'associate_product.product_image',
        'associate_product.measurement',
        'associate_product.product_tags',
        'associate_product.disable_order',
        'associate_product.order',
        'associate_product.final_price'
      ]
    }
    if (withs === undefined) {
      withs = [
        'categories',
        'associate_products'
      ]
    }
    if (appends === undefined) {
      appends = [
        'final_price'
      ]
    }
    return this.genericRequest(
      `/api/webclient/restaurant/${slug}/product`,
      fields,
      withs,
      appends,
      {
        mainProduct: 1
      }
    )
  }

  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  getRestaurants(fields = [], withs = [], appends = [], where = {}, coords = {}) {
    if (!fields.length) fields = [
      'id',
      'display_name',
      'slug',
      'settings',
      'offers_delivery',
      'offers_pickup',
      'delivery_time',
      'payment_type.label',

    ];
    if (withs === undefined) withs = [
      'paymentTypes',
      'deliveryCities'
    ];
    if (appends === undefined) appends = [
      'ratingsummary',
      'logo_filename',
      'minimum_delivery_amount'
    ];
    return this.genericRequest(
      '/api/webclient/restaurant',
      fields,
      withs,
      appends,
      where,
      [],
      coords
    )
  }

  /**
   * Usage example:
   * '/api/webclient/restaurant',
   * [
   *     'id',
   *     'name',
   *     'vat_no',
   *     'category.title'
   * ],
   * [
   *     'categories',
   * ],
   * [
   *     'full_address'
   * ],
   * {
   *     'name': 'big mamma',
   *     'county': 'mures'
   * }
   * @param resource String
   * @param fields Array
   * @param withs Array
   * @param appends Array
   * @param conditions Object
   * @param sortBy Array
   * @param extraParams Object
   * @returns {Promise<AxiosResponse<T>>}
   */
  genericRequest(resource = undefined, fields = [], withs = [], appends = [], conditions = {}, sortBy = [], extraParams = {}) {
    let finalConditions = Object.keys(conditions).map(function (prop) {
      return [`where[${prop}]`, conditions[prop]].map(encodeURIComponent).join("=");
    }).join("&");
    let finalExtraParams = Object.keys(extraParams).filter(prop => {
      return extraParams[prop] !== undefined;
    }).map(function (prop) {
      return [prop, extraParams[prop]].map(encodeURIComponent).join("=");
    }).join("&");
    return this.app.$axios
      .get(`${resource}?fields=${fields.join(',')}&append=${appends.join(',')}&with=${withs.join(',')}&sort=${sortBy.join(',')}&${finalConditions}&${finalExtraParams}`)
  }

  /**
   *
   * @param lat
   * @param lng
   * @returns {Promise<AxiosResponse<T>>}
   */
  getGeocode(lat, lng) {
    return this.app.$axios
      .get(`/api/webclient/location-geocode?latLng=${lat},${lng}`)
  }

  /**
   *
   * @param lat
   * @param lng
   * @returns {Promise<AxiosResponse<T>>}
   */
  getStreetGeocode(lat, lng) {
    return this.app.$axios
      .get(`/api/webclient/street-geocode?latLng=${lat},${lng}`)
  }

  /**
   * @returns {Promise<AxiosResponse<T>>}
   */
  getCart(params = {}) {
    return this.app.$axios
      .get(`/api/webclient/cart`, {params: params})
  }

  /**
   * @param product_id
   * @param quantity
   * @param relative
   * @param force
   * @returns {Promise<AxiosResponse<T>>}
   */
  addToCart(product_id, quantity, relative, force) {
    return this.app.$axios
      .post(`/api/webclient/cart`, {
        product_id,
        quantity,
        relative,
        force
      })
  }

  /**
   * @param product_id
   * @returns {Promise<AxiosResponse<T>>}
   */
  removeFromCart(product_id) {
    return this.app.$axios
      .delete(`/api/webclient/cart?product_id=${product_id}`)
  }

  /**
   * @param client_id
   * @param delivery_method
   * @param delivery_address_id
   * @param payment_type_id
   * @param voucher
   * @param remarks
   * @returns {Promise<AxiosResponse<T>>}
   */
  submitCart(client_id, delivery_method, delivery_address_id, payment_type_id, voucher, remarks, pickup_time, delivery_time) {
    return this.app.$axios
      .post(`/api/webclient/cart/submit`, {
        client_id,
        delivery_method,
        delivery_address_id,
        payment_type_id,
        voucher,
        remarks,
        pickup_time,
        delivery_time
      })
  }

  /**
   * @param token
   * @returns {Promise<AxiosResponse<T>>}
   */
  registerPushToken(token) {
    return this.app.$axios
      .post(`/api/webclient/register-push-token`, {
        token
      })
  }

  /**
   * @param token
   * @returns {Promise<AxiosResponse<T>>}
   */
  unregisterPushToken(token) {
    return this.app.$axios
      .post(`/api/webclient/unregister-push-token`, {
        token
      })
  }
}

export default function ({app}) {
  app.$api = new Api(app)
  Vue.prototype.$api = app.$api
}
