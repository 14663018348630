import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

//import SampleComponent from '~/components/SampleComponent'
//Vue.component('sample-component', SampleComponent)

// Common
import Header from '@/components/common/Header.vue';
Vue.component('Header',Header);
import EmbedHeader from '@/components/common/EmbedHeader.vue';
Vue.component('EmbedHeader',EmbedHeader);
import Logo from '@/components/common/Logo.vue';
Vue.component('Logo',Logo);

import AddToCart from '@/components/buttons/AddToCart.vue';
Vue.component('AddToCart', AddToCart);

import AddToCartModal from '@/components/buttons/AddToCartModal.vue';
Vue.component('AddToCartModal', AddToCartModal);

import Delivery from '@/components/buttons/Delivery.vue';
Vue.component('Delivery', Delivery);

import Footer from '@/components/common/Footer.vue';
Vue.component('Footer', Footer);

import BackDrop from '@/components/common/Backdrop.vue';
Vue.component('Backdrop', BackDrop);

import HeaderSearch from '@/components/common/HeaderSearch.vue';
Vue.component('HeaderSearch', HeaderSearch);

import Language from '@/components/language/Language.vue';
Vue.component('Language', Language);

import Cart from '@/components/cart/Cart.vue';
Vue.component('Cart', Cart);

import CartOverview from '@/components/cart/CartOverview.vue';
Vue.component('CartOverview', CartOverview);


// Slider
import HooperSlider from '@/components/sliders/HooperSlider.vue';
Vue.component('HooperSlider', HooperSlider);

// Filters
import StickyFilter from '@/components/filters/StickyFilter.vue';
Vue.component('StickyFilter', StickyFilter);

import Bullets from '@/components/filters/Bullets.vue';
Vue.component('Bullets', Bullets);

// User related
import HeaderMenu from '@/components/user/HeaderMenu.vue';
Vue.component('HeaderMenu', HeaderMenu);

import HeaderSecondLine from '@/components/user/HeaderSecondLine.vue';
Vue.component('HeaderSecondLine', HeaderSecondLine);

import Login from '@/components/user/Login.vue';
Vue.component('Login', Login);

import PasswordReset from '@/components/user/PasswordReset.vue';
Vue.component('PasswordReset', PasswordReset);

import Register from '@/components/user/Register.vue';
Vue.component('Register', Register);

import Authorization from '@/components/user/Authorization.vue';
Vue.component('Authorization', Authorization);

// Restaurant Overview
import RestaurantOverview from '@/components/restaurant/RestaurantOverview.vue';
Vue.component('RestaurantOverview', RestaurantOverview);

// Restaurant Detail
import RestaurantDetail from '@/components/restaurant/RestaurantDetail.vue';
Vue.component('RestaurantDetail', RestaurantDetail);

// Restaurant item
import RestaurantItem from '@/components/restaurant/RestaurantItem.vue';
Vue.component('RestaurantItem', RestaurantItem);

import UserReview from '@/components/restaurant/UserReview.vue';
Vue.component('UserReview', UserReview);

import Foodfilter from "../components/common/Foodfilter";
Vue.component('Foodfilter',Foodfilter);


// Product item
import ProductItem from '@/components/product/ProductItem.vue';
Vue.component('ProductItem', ProductItem);

import Profile from '@/components/user/my-account/Profile.vue';
Vue.component('Profile', Profile);
import OnesignalNotificationSubscribe from '@/components/user/OnesignalNotificationSubscribe.vue';
Vue.component('OnesignalNotificationSubscribe', OnesignalNotificationSubscribe);

import AddressesWrapper from '@/components/user/my-account/addresses/AddressesWrapper.vue';
Vue.component('AddressesWrapper', AddressesWrapper);
import Addresses from '@/components/user/my-account/addresses/Addresses.vue';
Vue.component('Addresses', Addresses);
import Address from '@/components/user/my-account/addresses/Address.vue';
Vue.component('Address', Address);

import Orders from '@/components/user/my-account/orders/Orders.vue';
Vue.component('Orders', Orders);
import Order from '@/components/user/my-account/orders/Order.vue';
Vue.component('Order', Order);

import Vouchers from '@/components/user/my-account/vouchers/Vouchers.vue';
Vue.component('Vouchers', Vouchers);
import Voucher from '@/components/user/my-account/vouchers/Voucher.vue';
Vue.component('Voucher', Voucher);

import Notifications from '@/components/user/my-account/notifications/Notifications.vue';
Vue.component('Notifications', Notifications);
import Notification from '@/components/user/my-account/notifications/Notification.vue';
Vue.component('Notification', Notification);
import NotificationManager from '@/components/user/my-account/notifications/NotificationManager.vue';
Vue.component('NotificationManager', NotificationManager);
import PlainCheckbox from '@/components/user/my-account/notifications/PlainCheckbox.vue';
Vue.component('PlainCheckbox', PlainCheckbox);
import BtnGroup from '@/components/user/my-account/notifications/BtnGroup.vue';
Vue.component('BtnGroup', BtnGroup);

import FaqOverview from '@/components/faq/FaqOverview.vue';
Vue.component('FaqOverview', FaqOverview);
import FaqItem from '@/components/faq/FaqItem.vue';
Vue.component('FaqItem', FaqItem);

import LocationSelectorHome from '@/components/locations/LocationSelectorHome.vue';
Vue.component('LocationSelectorHome', LocationSelectorHome);

import LocationSelector from '@/components/locations/LocationSelector.vue';
Vue.component('LocationSelector', LocationSelector);

import HeaderImageComponent from '@/components/headerImage/HeaderImageComponent.vue';
Vue.component('HeaderImageComponent', HeaderImageComponent);

import Statistics from '@/components/statistics/Statistics.vue';
Vue.component('Statistics', Statistics);

import ComponentSpacer from '@/components/common/ComponentSpacer.vue';
Vue.component('ComponentSpacer', ComponentSpacer);

import Popup from '@/components/common/Popup.vue';
Vue.component('Popup', Popup);

import HowToOrder from '@/components/howToOrder/HowToOrder.vue';
Vue.component('HowToOrder', HowToOrder);

import Playstore from '@/components/playstore/Playstore.vue';
Vue.component('Playstore', Playstore);

import Content from '@/components/content/Content.vue';
Vue.component('Content', Content);

import PremiumCorner from '@/components/common/PremiumCorner.vue';
Vue.component('PremiumCorner', PremiumCorner);


import Separator from '@/components/common/Separator.vue';
Vue.component('Separator', Separator);
