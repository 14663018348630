import Vue from 'vue'
import Router from 'vue-router'

import Home from '~/pages/Home'
import Restaurants from '~/pages/Restaurants'
import FAQ from '~/pages/FAQ'
import Cart from '~/pages/Cart'
import Login from '~/pages/Login'
import Register from '~/pages/Register'
import PasswordReset from '~/pages/PasswordReset'
import MyAccount from '~/pages/MyAccount'
import NotFound from '~/pages/NotFound'
import RestaurantEmbedded from "~/pages/embed/RestaurantEmbedded";
import CartEmbedded from "~/pages/embed/CartEmbedded";
import AuthEmbedded from "~/pages/embed/AuthEmbedded";

Vue.use(Router)

const routes = [
  { path: '/', name: 'root', component: Home },
  { path: '/autentificare/:token?/:email?', name: 'login', component: Login },
  { path: '/inregistrare', name: 'register', component: Register },
  { path: '/recuperare-parola', name: 'password-reset', component: PasswordReset },
  {
    path: '/contul-meu',
    name: 'my-account',
    component: MyAccount
  },
  {
    path: '/contul-meu/:slug',
    name: 'my-account-subpage',
    component: MyAccount
  },
  {
    path: '/locatii',
    name: 'restaurants',
    component: Restaurants
  },
  {
    path: '/locatii-in/:county',
    name: 'restaurants-in-county',
    component: Restaurants
  },
  {
    path: '/locatii-in/:county/:city',
    name: 'restaurants-in-city',
    component: Restaurants
  },
  {
    path: '/locatie/:slug',
    name: 'restaurant-detail',
    component: Restaurants
  },
  {
    path: '/ajutor/:slug?',
    name: 'faq',
    component: FAQ
  },
  {
    path: '/cos',
    name: 'cart',
    component: Cart
  },
  {
    path: '/embed-restaurant/:slug/:type?',
    name: 'embed-restaurant-detail',
    component: RestaurantEmbedded
  },
  {
    path: '/embed-cart',
    name: 'embed-cart',
    component: CartEmbedded
  },
  {
    path: '/embed-auth',
    name: 'embed-auth',
    component: AuthEmbedded
  },
  { path: '/404', component: NotFound },
  { path: '*', redirect: '/404' }
]

export function createRouter () {
  return new Router({
    routes,
    mode: process.env.ROUTER_MODE ? process.env.ROUTER_MODE : 'history'
  })
}
