export default {
  methods: {
    async assemblyDeliveryCitiesList (deliveryCities) {
      var cityId = parseInt(this.$store.getters['location/cityId'] || '0')
      var activeCity = {}
      var otherCities = []
      if(cityId < 1) {
        await this.getDeviceLocation().then((r) => {
          cityId = this._.get(r, 'data.id', 0)
        })
      }
      if(cityId > 0) {
        let foundDeliveryCity = deliveryCities.findIndex((deliveryCity) => {
          return deliveryCity.city_id === cityId
        })
        if (foundDeliveryCity >= 0) {
          activeCity = deliveryCities.splice(foundDeliveryCity, 1)[0]
          otherCities = deliveryCities
        }
      } else {
        if(deliveryCities.length) {
          activeCity = deliveryCities.splice(0, 1)[0]
          otherCities = deliveryCities
        } else {
          return undefined
        }
      }
      return {
        activeCity,
        otherCities
      }
    }
  }
}
