import { setup } from 'axios-cache-adapter'
import {get} from "lodash-es";
const isServerSide = typeof window === 'undefined'
if (isServerSide) {
  console.log('isServerSide', isServerSide)
  console.log('Loading dotenv....')
  require("dotenv").config();
}

export default {
  async fetchHead (pagePath = 'root', locale = 'ro') {
    if(!isServerSide) return {}

    const instance = setup({
      baseURL: process.env.API_URL,
      cache: {
        maxAge: 15 * 60 * 1000
      }
    })
    return await instance.get(`/api/webclient/pages?lang=${locale}`)
      .then((r) => {
        return get(r, 'data')
      }).then((r) => {
        var ret = get(r.filter((page) => page.path === pagePath), '0', {})

        console.log(`Getting pagePath in SSR for ${pagePath}, result title: ${ret.title}`)

        return ret
      })
  },
  async getRestaurants () {
    if(!isServerSide) return []

    const instance = setup({
      baseURL: process.env.API_URL,
      cache: {
        maxAge: 15 * 60 * 1000
      }
    })

    instance.interceptors.request.use(function (config) {
      // THis is good for debugging
      //console.log(config)
      return config
    }, function (error) {
      return Promise.reject(error)
    })

    return await instance.get(`/api/webclient/restaurant?perPage=1000&fields=id,display_name,address,address_no,slug,county,city,settings,phone_numbers,image.image&appends=ratingsummary,logo_filename&with=images`)
    .then((res) => {
      return get(res, 'data.data')
    })
    .catch((err) => {
      console.error(err.toString())
    })
  },
  async getFAQPages () {
    if(!isServerSide) return []

    const instance = setup({
      baseURL: process.env.API_URL,
      cache: {
        maxAge: 15 * 60 * 1000
      }
    })

    instance.interceptors.request.use(function (config) {
      // THis is good for debugging
      //console.log(config)
      return config
    }, function (error) {
      return Promise.reject(error)
    })

    return await instance.get(`/api/webclient/content?perPage=1000&fields=title,content,slug,attributes`)
    .then((res) => {
      return get(res, 'data.data')
    })
    .catch((err) => {
      console.error(err.toString())
    })
  }
}
