export default {
  methods: {
    getWorkhoursObject (restaurant) {
      var workhours = {}
      if (restaurant.settings?.workhour_monday_a)
        workhours.monday = {
          start: restaurant.settings?.workhour_monday_a,
          end: restaurant.settings?.workhour_monday_b
        }
      if (restaurant.settings?.workhour_tuesday_a)
        workhours.tuesday = {
          start: restaurant.settings?.workhour_tuesday_a,
          end: restaurant.settings?.workhour_tuesday_b
        }
      if (restaurant.settings?.workhour_wednesday_a)
        workhours.wednesday = {
          start: restaurant.settings?.workhour_wednesday_a,
          end: restaurant.settings?.workhour_wednesday_b
        }
      if (restaurant.settings?.workhour_thursday_a)
        workhours.thursday = {
          start: restaurant.settings?.workhour_thursday_a,
          end: restaurant.settings?.workhour_thursday_b
        }
      if (restaurant.settings?.workhour_friday_a)
        workhours.friday = {
          start: restaurant.settings?.workhour_friday_a,
          end: restaurant.settings?.workhour_friday_b
        }
      if (restaurant.settings?.workhour_saturday_a)
        workhours.saturday = {
          start: restaurant.settings?.workhour_saturday_a,
          end: restaurant.settings?.workhour_saturday_b
        }
      if (restaurant.settings?.workhour_sunday_a)
        workhours.sunday = {
          start: restaurant.settings?.workhour_sunday_a,
          end: restaurant.settings?.workhour_sunday_b
        }
      return workhours
    },
    buildRichSnippet (restaurant) {
      var openingHours = []
      var path = ''
      if(typeof window === 'undefined' || process.env.APP_URL !== undefined) {
        path = `${process.env.APP_URL}/locatie/${restaurant.slug}`
      } else {
        path = window.location.protocol.concat("//").concat(window.location.hostname).concat(this.$route.path);
      }
      /** @TODO: fix menu **/
      var snippet = {
        "@context": "https://schema.org",
        "@type": "Restaurant",
        "@id": path,
        "name": restaurant.display_name,
        "address": `${restaurant.address} ${restaurant.address_no}, ${restaurant.city}, ${restaurant.county}`,
        "url": path,
        "menu": path
      }
      if (restaurant.images?.data) {
        snippet.image = restaurant.images.data.map((image) => this.getGalleryImage(restaurant.id, image.image, 350, 350))
      }
      if (restaurant.ratingsummary) {
        snippet.aggregateRating =  {
          "@type": "AggregateRating",
          "bestRating": restaurant.ratingsummary.max,
          "ratingCount": restaurant.ratingsummary.count,
          "ratingValue": restaurant.ratingsummary.avg
        }
      }

      if (!this._.isNil(restaurant.phone_numbers)) {
        snippet.telephone = restaurant.phone_numbers.split(/\n/)[0]
      }

      let workhoursObject = this.getWorkhoursObject(restaurant)
      Object.keys(workhoursObject).forEach((day) => {
        openingHours.push({
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": day.charAt(0).toUpperCase() + day.slice(1),
          "opens": workhoursObject[day]?.start,
          "closes": workhoursObject[day]?.end
        })
      })
      if (openingHours.length) {
        snippet.openingHoursSpecification = openingHours
      }
      /*
      @TODO: implement this
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 40.761293,
        "longitude": -73.982294
      },
       */
      return snippet
    },
    buildHead (page, restaurant) {
      var path = ''
      if(typeof window === 'undefined' || process.env.APP_URL !== undefined) {
        path = `${process.env.APP_URL}/locatie/${restaurant.slug}/`
      } else {
        path = window.location.protocol.concat("//").concat(window.location.hostname).concat(this.$route.path) + '/';
      }
      var title = this._.get(page, 'title', '')
        .replace('{restaurant}', this._.get(restaurant, 'display_name', ''))
      var meta_title = this._.get(page, 'meta_title', '')
        .replace('{restaurant}', this._.get(restaurant, 'display_name', ''))
      var meta_description = this._.get(page, 'meta_description', '')
        .replace('{restaurant}', this._.get(restaurant, 'display_name', ''))
      var ret = {
        title: title,
        meta: [
          { hid: 'title', name: 'title', content: meta_title },
          { hid: 'og:title', property: 'og:title', content: title },
          { hid: 'og:type', property: 'og:type', content: 'website'},
          { hid: 'og:url', property: 'og:url', content: path},
          { hid: 'fb:app_id', property: 'fb:app_id', content: process.env.FB_APPID}
        ]
      }
      if(meta_description.length) {
        ret.meta.push({ hid: 'description', name: 'description', content: meta_description })
        ret.meta.push({ hid: 'og:description', property: 'og:description', content: meta_description })
      }
      if(restaurant?.settings?.logo) {
        ret.meta.push({ hid: 'og:image', property: 'og:image', content: this.getImage('images', restaurant.settings.logo, 350, 350) })
      }

      try {
        let snippet = this.buildRichSnippet(restaurant)
        ret.script = [
          { type: 'application/ld+json', json: snippet }
        ]
      }catch (e) {
        console.error(e)
      }
      return ret
    }
  }
}
