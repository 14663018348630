// state
export const state = () => ({
  embeddedRestaurantSlug: undefined,
  redirectAfterSocialLogin: false
})

// getters
export const getters = {
  embeddedRestaurantSlug: state => state.embeddedRestaurantSlug,
  redirectAfterSocialLogin: state => state.redirectAfterSocialLogin
}

// mutations
export const mutations = {
  SET_EMBEDDED_RESTAURANT_SLUG (state, { embeddedRestaurantSlug }) {
    state.embeddedRestaurantSlug = embeddedRestaurantSlug
  },
  SET_REDIRECT_AFTER_SOCIAL_LOGIN (state, { status }) {
    state.redirectAfterSocialLogin = status
  }
}

// actions
export const actions = {
  setEmbeddedRestaurantSlug ({ commit }, { embeddedRestaurantSlug }) {
    commit('SET_EMBEDDED_RESTAURANT_SLUG', { embeddedRestaurantSlug })
  },
  setRedirectAfterSocialLogin ({ commit }, { status }) {
    commit('SET_REDIRECT_AFTER_SOCIAL_LOGIN', { status })
  }
}
