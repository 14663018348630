// state
export const state = () => ({
  pages: JSON.stringify([]),
  locale: process.env.LOCALE || 'ro',
  locales: {
    'en': 'EN',
    'ro': 'RO'
  },
  localesTranslation: {
    'en': 'en-us',
    'ro': 'ro-ro'
  },
  localesMapping: [
    {
      lang: 'en',
      text: 'En'
    },
    {
      lang: 'ro',
      text: 'Ro'
    }
  ]
})

// getters
export const getters = {
  pages: state => JSON.parse(state.pages),
  locale: state => state.locale,
  locales: state => state.locales,
  localesTranslation: state => state.localesTranslation,
  localesMapping: state => state.localesMapping
}

// mutations
export const mutations = {
  SET_LOCALE (state, { locale }) {
    if(locale !== undefined) {
      state.locale = locale
    }
  },
  SET_PAGES (state, { pages }) {
    if(pages !== undefined) {
      typeof window !== 'undefined' ? window.localStorage.setItem('pages', JSON.stringify(pages)) : null
      state.pages = JSON.stringify(pages)
    }
  }
}

// actions
export const actions = {
  setLocale ({ commit }, { locale }) {

    if(locale !== undefined) {
      commit('SET_LOCALE', {locale})
    }
  },
  setPages ({ commit }, { pages }) {

    if(pages !== undefined) {
      commit('SET_PAGES', {pages})
    }
  }
}
