// state
export const state = () => ({
  all: true,
  categoryId: undefined
})

// getters
export const getters = {
  all: state => state.all,
  categoryId: state => state.categoryId
}

// mutations
export const mutations = {
  SET_RESTAURANT_TYPE (state, { all }) {
    state.all = all
  },
  SET_RESTAURANT_CATEGORY_ID (state, { categoryId }) {
    state.categoryId = categoryId
  }
}

// actions
export const actions = {
  setRestaurantType ({ commit }, { all }) {
    commit('SET_RESTAURANT_TYPE', { all })
  },
  setRestaurantCategoryId ({ commit }, { categoryId }) {
    commit('SET_RESTAURANT_CATEGORY_ID', { categoryId })
  }
}
